@import "../../../data/styles.css";

.works-body {
	margin-bottom: 10px;
}

.work {
	display: flex;
	padding-bottom: 40px;
	width: 400px;
}

.work-image {
	height: 30px;
	width: 30px;
	border-radius: 50%;
	outline: 6px solid white;
	box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
}

.work-title {
	font-size: 15px;
	font-weight: 700;
	padding-left: 20px;
	margin-top: -3px;
	color: var(--secondary-color);
}

.work-subtitle {
	position: absolute;
	font-size: 12px;
	color: var(--secondary-color);
	padding-top: 22px;
	padding-left: 50px;
}

.work-duration {
	position: absolute;
	width: 350px;
	font-size: 12px;
	padding-top: 20px;
	text-align: right;
	color: var(--tertiary-color);
}

.works {
	margin: 3rem 0;
}

.card {
	border-radius: 15px;
	overflow: hidden;
	box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}

.card-container {
	padding: 20px;
}

.card-title {
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 15px;
}

.location-card {
	background: white;
	border-radius: 15px;
	padding: 2rem;
	box-shadow: 0 2px 15px rgba(0,0,0,0.08);
	display: flex;
	align-items: center;
	gap: 1.5rem;
	transition: all 0.3s ease;
}

.location-card:hover {
	transform: translateY(-5px);
	box-shadow: 0 5px 20px rgba(0,0,0,0.12);
}

.location-icon {
	font-size: 2rem;
	color: #ff6fe6;
}

.location-details {
	flex: 1;
}

.location-details h3 {
	margin: 0 0 0.5rem 0;
	color: #333;
	font-family: var(--secondary-font);
}

.location-details p {
	margin: 0 0 1rem 0;
	color: #666;
	line-height: 1.5;
}

.directions-link {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 8px 20px;
	background-color: #ff6fe6;
	color: white;
	border-radius: 20px;
	text-decoration: none;
	font-size: 0.9rem;
	transition: all 0.3s ease;
	width: fit-content;
	margin: 0 auto;
}

.directions-link:hover {
	background-color: #ff4fe0;
	transform: translateY(-2px);
}

/* Mobile Styles */
@media (max-width: 768px) {
	.works {
		margin: 2rem 0;
	}

	.card-container {
		padding: 15px;
	}

	.card-title {
		font-size: 16px;
		margin-bottom: 10px;
	}

	/* Adjust map height for mobile */
	.card-body div {
		height: 300px !important;
	}

	.location-card {
		padding: 1.5rem;
		flex-direction: column;
		text-align: center;
		gap: 1rem;
	}

	.location-icon {
		font-size: 1.75rem;
	}

	.directions-link {
		width: 100%;
		max-width: 200px;
	}
}
