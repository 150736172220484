@import "../../data/styles.css";

.services {
	margin-top: 2em;
}

.homepage-logo-container {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 130px;
	height: 80px;
	margin-bottom: 1rem;
	contain: strict;
}

.homepage-container {
	padding-top: 1.5rem;
	overflow-x: hidden;
	min-height: 100vh;
	position: relative;
	contain: paint;
}

.homepage-first-area {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1.5rem;
	transform: translateZ(0);
	backface-visibility: hidden;
	perspective: 1000px;
}

.homepage-first-area-left-side {
	flex: 1;
	padding-right: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	max-width: 100%;
}

.homepage-first-area-right-side {
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
}

.homepage-title {
	width: 100% !important;
	background: linear-gradient(to right, rgba(255, 111, 230, 0.1), rgba(255, 184, 201, 0.2));
	padding: 1rem;
	border-radius: 15px;
	margin-bottom: 1.5rem;
	text-align: center;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
	box-sizing: border-box;
}

.homepage-subtitle {
	width: 100% !important;
	text-align: center;
}

.homepage-image-container {
	width: 100%;
	max-width: 500px;
	margin: 0 auto;
	padding-bottom: 0;
}

.homepage-image {
	display: block;
	width: 100%;
	height: auto;
	border-radius: 20px;
	object-fit: cover;
	aspect-ratio: 4/5;
}

.homepage-socials {
	display: flex;
	justify-content: center;
	padding: 0.5rem 0;
	margin-bottom: 2rem;
	font-size: 2em;
}

.homepage-socials a {
	text-decoration: none;
	color: white;
	font-style: normal;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #ff6fe6;
	border: none;
	border-radius: 25px;
	padding: 12px 30px;
	font-size: 1.1rem;
	font-weight: 600;
	transition: all 0.3s ease;
	box-shadow: 0 2px 10px rgba(255, 111, 230, 0.2);
}

.homepage-socials a:hover {
	background-color: #ff4fe0;
	transform: translateY(-2px);
	box-shadow: 0 4px 15px rgba(255, 111, 230, 0.3);
}

.homepage-social-icon {
	margin-left: 10px;
	font-size: 25px;
	transition: color 0.2s ease-in-out;
	color: var(--secondary-color);
}

.homepage-social-icon:hover {
	color: var(--primary-color);
}

.homepage-after-title {
	display: flex;
}

.homepage-projects {
	flex-basis: 300px;
	margin-bottom: 2rem;
	transform: translateZ(0);
	backface-visibility: hidden;
	perspective: 1000px;
}

.homepage-articles {
	flex-basis: 900px;
	padding-top: 30px;
	margin-left: -30px;
}

.homepage-article {
	padding-bottom: 5px;
}

.homepage-works {
	flex-basis: 600px;
	padding-top: 30px;
	margin-bottom: 2rem;
	transform: translateZ(0);
	backface-visibility: hidden;
	perspective: 1000px;
}

.services-heading {
	text-align: center;
	padding: 20px;
	margin-top: 1em;
}

.h1-heading-style {
	background-color: rgba(240, 248, 255, 0.38);
	/*background: linear-gradient(135deg, rgba(236, 244, 248, 0.2), rgba(0, 75, 255, 0.2));*/
	color: #333;
	border-radius: 5px;
	border-width: 2px;
	border: black;
}

.project-container img {
	width: 100%;
	max-width: 350px;
	height: 280px;
	/*padding-bottom: 10px;*/
	border-radius: 10px;
}

@media (max-width: 640px) {
	.project-container {
		padding: 20px;
	}
}

@media (max-width: 768px) {
	.homepage-first-area {
		flex-direction: column-reverse;
		margin-bottom: 1rem;
	}

	.homepage-first-area-left-side {
		padding-right: 0;
		text-align: center;
		margin-top: 0;
	}

	.homepage-title {
		width: 100% !important;
		font-size: 24px;
		line-height: 1.3;
		margin-bottom: 1rem;
		padding: 0.8rem;
	}

	.homepage-subtitle {
		font-size: 16px;
		line-height: 1.5;
	}

	.homepage-image-container {
		padding: 0 1rem;
	}

	.homepage-image {
		height: auto;
		width: 100%;
		max-height: 450px;
	}

	.homepage-socials {
		text-align: center;
		padding: 0.5rem 0;
		margin-bottom: 1.5rem;
	}

	.homepage-socials a {
		padding: 12px 24px;
		font-size: 16px;
		border-radius: 25px;
		background-color: #ff6fe6;
		color: white;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: background-color 0.3s ease;
	}

	.homepage-social-icon {
		margin-left: 8px;
	}

	.homepage-first-area-right-side {
		width: 100%;
		padding: 0 15px;
		margin-bottom: 1rem;
	}

	.homepage-image-container {
		max-width: 350px;
		padding: 0;
	}

	.homepage-image {
		height: auto;
		width: 100%;
		max-height: 450px;
	}

	.page-heading {
		padding: 1rem;
		margin-bottom: 1rem;
	}

	.page-heading h1 {
		font-size: 2rem;
	}

	.heading-subtitle {
		font-size: 1rem;
	}

	.homepage-container {
		padding-top: 1rem;
	}

	.homepage-projects {
		margin-bottom: 1.5rem;
	}

	.homepage-works {
		margin-bottom: 1.5rem;
	}

	.section-title h2 {
		font-size: 1.75rem;
	}

	.section-subtitle {
		font-size: 1rem;
	}

	.homepage-socials {
		margin: 1.5rem 0;
	}

	.homepage-socials a {
		padding: 10px 25px;
		font-size: 1rem;
	}
}

@media (max-width: 1024px) {

	.project-container img {
		width: 100%;
		max-width: 350px;
		height: 200px;
		/*padding-bottom: 10px;*/
		border-radius: 10px;
	}

	.homepage-image-wrapper {
		transform: rotate(0deg);
	}

	.homepage-first-area {
		flex-direction: column;
		margin-top: 2em;
	}

	.homepage-first-area-left-side {
		display: flex;
		flex-direction: column; /* Changed: set direction to column */
		align-items: flex-start;
		width: 100%;
		order: 2;
	}

	.homepage-title {
		width: 100% !important;
		margin: 20px 0;
		order: 2;
		padding: 1rem;
		box-sizing: border-box;
	}

	.homepage-subtitle {
		width: 100% !important;
		margin-top: 10px;
		order: 3;
	}

	.homepage-first-area-right-side {
		width: 100%;
		order: 1;
		margin-bottom: 1em;
	}

	.homepage-image-container {
		padding-bottom: 0;
		margin-left: 10px;
		order: 1;
		max-width: 400px;
		margin: 0 auto;
	}

	.homepage-after-title {
		flex-direction: column;
	}

	.homepage-projects {
		flex-basis: auto;
	}

	.homepage-articles {
		flex-basis: auto;
	}

	.homepage-works {
		flex-basis: auto;
		margin-left: -25px;
		margin-right: -25px;
	}

	.homepage-socials {
		display: flex;
		justify-content: center;
		align-items: center;
		padding-top: 30px;
		font-size: 2em;
	}
}

/* Add these styles for the page heading */
.page-heading {
	text-align: center;
	padding: 1.5rem;
	margin-bottom: 1.5rem;
	background: linear-gradient(to right, rgba(255, 111, 230, 0.1), rgba(255, 111, 230, 0.2));
	border-radius: 15px;
}

.page-heading h1 {
	font-size: 2.5rem;
	color: #333;
	margin: 0;
	padding: 0;
	font-weight: 600;
	font-family: var(--secondary-font);
}

.heading-subtitle {
	font-size: 1.2rem;
	color: #666;
	margin-top: 0.5rem;
	font-family: var(--primary-font);
	line-height: 1.5;
}

/* Add section titles */
.section-title {
	text-align: center;
	margin-bottom: 2rem;
}

.section-title h2 {
	font-size: 2rem;
	color: #333;
	margin-bottom: 0.5rem;
	font-family: var(--secondary-font);
}

.section-subtitle {
	font-size: 1.1rem;
	color: #666;
	font-family: var(--primary-font);
}

.page-content {
	background: var(--bg-primary);
	min-height: 100vh;
	overflow-x: hidden;
	position: relative;
	contain: paint;
}

