:root {
	/* ------- colors ------- */
	--primary-color: #27272a;
	--secondary-color: #71717a;
	--tertiary-color: #a1a1aa;
	--quaternary-color: #e4e4e7;
	--link-color: #14b8a6;
	/* ---------------------- */

	/* ------- fonts ------- */
	--primary-font: 'Poppins', sans-serif;
	--secondary-font: 'Playfair Display', serif;
	/* --------------------- */

	/* Font weights */
	--font-light: 300;
	--font-regular: 400;
	--font-medium: 500;
	--font-semibold: 600;
	--font-bold: 700;

	/* Add background colors */
	--bg-primary: #fdfbff;
	--bg-gradient: linear-gradient(to bottom, #fff6fb, #fdfbff);
}

/* Add some base typography styles */
h1, h2, h3, h4, h5, h6 {
	font-family: var(--secondary-font);
	font-weight: var(--font-semibold);
	letter-spacing: -0.02em;
}

body {
	font-family: var(--primary-font);
	font-weight: var(--font-regular);
	line-height: 1.6;
	letter-spacing: -0.01em;
	background: var(--bg-gradient);
	min-height: 100vh;
}

.title {
	font-family: var(--secondary-font);
	font-weight: var(--font-medium);
	letter-spacing: -0.02em;
}

.subtitle {
	font-family: var(--primary-font);
	font-weight: var(--font-light);
	letter-spacing: -0.01em;
}

.page-content {
	background: var(--bg-primary);
	min-height: 100vh;
}

/* Update card backgrounds for better contrast */
.project, .location-card, .page-heading {
	background: white;
	backdrop-filter: blur(10px);
}
