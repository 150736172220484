@import "../../../data/styles.css";

.footer {
	padding: 30px 0;
	margin-top: 50px;
	text-align: center;
	border-top: 1px solid #eee;
}

.footer-links,
.footer-credits {
	flex-basis: 50%;
}

.footer-links {
	margin-left: -40px;
}

.footer-nav-link-list {
	display: flex;
	list-style: none;
	justify-content: space-between;
	align-items: center;
}

.footer-nav-link-item {
	font-weight: bold;
	font-size: 80%;
}

.footer-nav-link-list a {
	text-decoration: none;
	color: var(--secondary-color);
}

.footer-nav-link-list a:hover {
	color: var(--link-color);
}

.footer-credits-text {
	font-size: 14px;
	color: #666;
}

/* Mobile Styles */
@media (max-width: 768px) {
	.footer {
		padding: 20px 0;
		margin-top: 30px;
	}

	.footer-credits-text {
		font-size: 12px;
	}
}
