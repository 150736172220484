.all-projects-container {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	padding: 30px 0;
	margin: 0 -10px;
}

.all-projects-project {
	width: calc(100% / 3);
	padding: 10px;
	box-sizing: border-box;
}

/* Mobile Styles */
@media (max-width: 768px) {
	.all-projects-container {
		padding: 15px 0;
	}

	.all-projects-project {
		width: 100%;
		padding: 10px 0;
	}
}
