@import "../../../data/styles.css";

.project {
	border-radius: 15px;
	background: white;
	box-shadow: 0 2px 15px rgba(0,0,0,0.08);
	height: 100%;
	transition: all 0.3s ease;
	overflow: hidden;
}

.project:hover {
	transform: translateY(-5px);
	box-shadow: 0 5px 20px rgba(0,0,0,0.12);
}

.project-container {
	padding: 20px;
}

.project-title {
	font-size: 1.25rem;
	font-weight: 600;
	color: #333;
	margin: 1rem 0;
	text-align: center;
}

.project-container img {
	width: 100%;
	height: 300px;
	object-fit: contain;
	border-radius: 20px;
	margin-bottom: 0;
	background: #f8f8f8;
	padding: 10px;
	box-shadow: 0 4px 15px rgba(0,0,0,0.05);
	transition: all 0.3s ease;
}

.project:hover .project-container img {
	box-shadow: 0 6px 20px rgba(0,0,0,0.08);
	transform: translateY(-2px);
}

.project-description {
	font-size: 0.95rem;
	line-height: 1.6;
	color: #666;
	margin-bottom: 1rem;
	text-align: center;
}

.project-logo {
	width: 30px;
}

.project-logo img {
	width: 100%;
}

.project-link {
	display: inline-block;
	padding: 8px 20px;
	background-color: #9c1458;
	color: #ffffff;
	border-radius: 20px;
	text-decoration: none;
	font-size: 0.9rem;
	transition: all 0.3s ease;
	margin-top: auto;
	font-weight: 500;
}

.project-link:hover {
	background-color: #7b0f45;
	color: #ffffff;
}

/* Mobile Styles */
@media (max-width: 768px) {
	.project {
		margin-bottom: 20px;
	}

	.project-container {
		padding: 15px;
	}

	.project-title {
		font-size: 16px;
		margin-bottom: 10px;
	}

	.project-container img {
		height: 250px;
	}

	.project-description {
		font-size: 13px;
	}
}

@media (max-width: 480px) {
	.project-container img {
		height: 200px;
	}
}

.project a {
	text-decoration: none;
	color: inherit;
}

.project:hover a {
	text-decoration: none;
}
