@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@300&family=Roboto:wght@500&display=swap");
@import "./data/styles.css";

body {
	margin: 0;
	padding: 0;
	font-family: var(--primary-font);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	line-height: 1.6;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

/* Global link styles */
a {
	text-decoration: none;
	color: inherit;
}

/* Mobile Styles */
@media (max-width: 768px) {
	.content-wrapper {
		padding: 0 15px;
	}

	.page-content {
		padding-top: 20px;
	}

	h1, h2, h3 {
		line-height: 1.3;
	}

	/* Improve tap targets */
	button, 
	a {
		min-height: 44px;
		min-width: 44px;
	}

	/* Improve form elements */
	input, 
	select, 
	textArea {
		font-size: 16px; /* Prevents iOS zoom on focus */
	}
}
